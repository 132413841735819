<template>
  <div class="orderManagementDetailsPageBox page-info-content">
    <Details-page :detail-obj="detailObj" :details-item-arr="detailsItemArr" />
  </div>
</template>

<script>
import DetailsPage from '@/components/DetailsPage.vue'
import { inDetail } from '@/api/transactionData'
export default {
  components: { DetailsPage },
  data() {
    return {
      detailObj: {},
      detailsItemArr: [
        { label: '合同编号', value: 'contractNo' },
        { label: '入库编号', value: 'inNo' },
        { label: '品名', value: 'goodsName', width: 200 },
        { label: '材质', value: 'goodsMaterials', width: 200 },
        { label: '规格', value: 'goodsSpecs', width: 200 },
        { label: '产地', value: 'goodsProduct', width: 200 },
        { label: '件重(吨)', value: 'goodsWeights' },
        { label: '入库数量', value: 'inAmount' },
        { label: '入库数量(吨)', value: 'inWeight' },
        { label: '长度(米)', value: 'length' }
      ]
    }
  },
  created() {
    if (this.$route.query.id) {
      this.getDetails()
    }
  },
  methods: {
    getDetails() {
      inDetail(this.$route.query.id, res => {
        this.detailObj = res.data
      })
    }
  }
}
</script>
